.homeImageContainer{
  display: flex;
  justify-content: center;
}


.homeImage{
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 80%;
}

.linksContainer{
  display: flex;
  justify-content: center;
}

.homeLinks{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5%;
}

.link{
  margin: 0 10px;
  color: black;
  text-decoration: none;
  background-color: transparent;
}

.link:hover{
  color:black;
  text-decoration: underline;
}