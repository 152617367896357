.aboutContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 5%;
  border: 2px solid lightgrey;
  padding: 1rem 0;
}

.infoDiv{
  text-align: left;
  font-size: 15px;
  width: 100%;
  height: auto;
  margin-left: -15%;
}

.imageDiv{
  display: flex;
  justify-content: center;
}

.aboutImage{
  width: 55%;
  height: auto;
}

@media only screen and (max-width: 1000px) {

  .mainContainer{
    display: flex;
    justify-content: center;
  }

  .aboutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 5%;
    border: 2px solid lightgrey;
    padding: 1rem 0;
  }

  .infoDiv{
    text-align: center;
    font-size: 15px;
    width: 100%;
    height: auto;
    margin: 1.2%;
  }

  .aboutImage{
    width: 55%;
    height: 55%;
  }
}