.navImageContainer{
  display: flex;
  justify-content: flex-start;
  
}

.navImage{
  width: 25%;
  height: auto;
}

.navLinks{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5%;
  padding-top: 5%;
}

.navContainer{
  margin-bottom: 3%;
}

@media only screen and (max-width: 1000px) {

  .navbar{
    display: flex;
    flex-direction: column;
    margin-top: 3%;
  }

  .navImageContainer{
    display: flex;
    justify-content: center;
  }

  .navImage{
    width: 50%;
    height: 50%;
  }
}